import moment from 'moment'

export function timeSince(
  date: moment.MomentInput,
  format: moment.unitOfTime.Diff = 'days'
): number {
  const dateCreated = moment(date)
  const today = moment()
  return today.diff(dateCreated, format)
}

export const dateString = (date?: Date | string): string =>
  moment(date || new Date())
    .format('MM-DD-YYYY')
    .toLowerCase()

export const dateInputString = (date?: Date | string): string => {
  if (!date) {
    return ''
  }

  return moment(date || new Date())
    .format('YYYY-MM-DD')
    .toLowerCase()
}

export function assembleTimeToFormat(duration: moment.DurationInputArg1): string {
  const assemble_time = moment.duration(duration, 'seconds')
  const hours = Math.floor(assemble_time.asHours())
  const mins = Math.floor(assemble_time.asMinutes()) - hours * 60
  const formattedMinutes = mins > 9 ? `${mins}` : `0${mins}`
  return `${hours}:${formattedMinutes} Hrs`
}

export function isDateToday(original: Date) {
  const now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

  const originalTime = original.getTime()
  const nowTime = now.getTime()

  const diff = originalTime - nowTime

  if (Number.isNaN(diff)) {
    return false
  }

  return diff === 0
}

export function daysInWeeks(days: number, roundNearestWeek = true) {
  const weeks = days / 7

  if (!roundNearestWeek) return weeks

  return Math.round(weeks)
}

export const addBusinessDays = (date, daysToAdd) => {
  const currentDate = moment(date)

  while (daysToAdd > 0) {
    currentDate.add(1, 'days')

    // Check if the current day is a weekday
    if (currentDate.isoWeekday() <= 5) {
      daysToAdd--
    }
  }

  return currentDate
}
